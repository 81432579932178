import { ChangePinComponent } from './Components/change-pin/change-pin.component';
import { ChangePasswordComponent } from './Components/change-password/change-password.component';
import { UnauthorizedUserComponent } from './Components/unauthorized-user/unauthorized-user.component';
import { environment } from 'src/environments/environment';
import { AuthService } from './Services/auth/auth.service';
import { LoginComponent } from './Components/login/login.component';
import { UploadIconComponent } from './Components/upload-icon/upload-icon.component';
import { IconsComponent } from './Components/icons/icons.component';
import { NullComponent } from './Components/null/null.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { Home1Component } from './Components/home1/home1.component';
import { LanguageComponent } from './Components/language/language.component';
import { AppDetailComponent } from './Components/app-detail/app-detail.component';
import { CreateAppComponent } from './Components/create-app/create-app.component';
import { InstanceDetailComponent } from './Components/instance-detail/instance-detail.component';
import { UserappinstanceService } from './Services/userappinstnce/userappinstance.service';
import { ManageInstancesComponent } from './Components/manage-instances/manage-instances.component';
import { Data } from './Helpers/Data';
import { BrowserModule, DomSanitizer, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, enableProdMode, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';

import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatCommonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatLineModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatPseudoCheckboxModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle, Router } from '@angular/router';
import { CreateInstanceComponent } from './Components/create-instance/create-instance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageComponent } from './Components/manage/manage.component';
import { ManageAppsComponent } from './Components/manage-apps/manage-apps.component';
import { Urls } from './Models/Urls';
import { PortalInfoComponent } from './Components/portal-info/portal-info.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CacheRouteReuseStrategy } from './Helpers/route-reuse.strategy';
import { AuthGuardService } from './Services/auth-guard/auth-guard.service';

import { AccordionModule } from 'primeng/accordion';     // accordion and accordion tab
import { MenuItem } from 'primeng/api';                  // api
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MenuModule } from 'primeng/menu';
import { ColorPickerModule } from 'primeng/colorpicker';

import { CookieService } from 'ngx-cookie-service';
import { OAuthModule } from 'angular-oauth2-oidc';

enableProdMode();

const keycloakService = new KeycloakService();
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// Detect browser
// https://www.npmjs.com/package/detect-browser
// const { detect } = require('detect-browser');
import { detect } from 'detect-browser';
import { UsersService } from './Services/users/users.service';
import { UserUpdater, KeyValue } from './Models/KeyValue';
import { User } from './Models/User';
import { Variables } from './Models/variables';
import { GroupsService } from './Services/groups/groups.service';
import { ImageService } from './Services/image/image.service';
import { LanguageService } from './Services/language/language.service';
import { PermissionService } from './Services/permission/permission.service';
const browser = detect();

@NgModule({
   declarations: [
      AppComponent,
      Home1Component,
      CreateInstanceComponent,
      ManageComponent,
      InstanceDetailComponent,
      ManageAppsComponent,
      ManageInstancesComponent,
      CreateAppComponent,
      AppDetailComponent,
      LanguageComponent,
      PortalInfoComponent,
      NotificationComponent,
      NullComponent,
      IconsComponent,
      UploadIconComponent,
      LoginComponent,
      UnauthorizedUserComponent,
      ChangePasswordComponent,
      ChangePinComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      KeycloakAngularModule,
      OAuthModule.forRoot(),
      MatMenuModule,
      MatSidenavModule,
      MatListModule,
      HttpClientModule,
      MatCardModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatCommonModule,
      MatDatepickerModule,
      MatDialogModule,
      MatDividerModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatLineModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatOptionModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatPseudoCheckboxModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSlideToggleModule,
      MatSliderModule,
      MatSnackBarModule,
      MatSortModule,
      MatStepperModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      FlexLayoutModule,
      FormsModule,
      ReactiveFormsModule,

    AccordionModule,
    PanelModule,
    ButtonModule,
    RadioButtonModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    MenubarModule,
    InputTextModule,
    ProgressSpinnerModule,
    PickListModule,
    CardModule,
    DialogModule,
    ScrollPanelModule,
    MenuModule,
    ColorPickerModule,

      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      RouterModule.forRoot([
        { path: 'unsupported-browser', component: NullComponent },
        { path: 'home', redirectTo: 'home/', pathMatch: 'full' },
        { path: 'home/:name', component: Home1Component },
        { path: 'manage', component: ManageComponent, canActivate: [ AuthGuardService ] },
        { path: 'manageinstances', component: ManageInstancesComponent, canActivate: [ AuthGuardService ] },
        { path: 'createinstance', component: CreateInstanceComponent, canActivate: [ AuthGuardService ] },
        { path: 'instancedetail', component: InstanceDetailComponent, canActivate: [ AuthGuardService ] },
        { path: 'manageapps', component: ManageAppsComponent, canActivate: [ AuthGuardService ] },
        { path: 'createapp', component: CreateAppComponent, canActivate: [ AuthGuardService ] },
        { path: 'appdetail', component: AppDetailComponent, canActivate: [ AuthGuardService ] },
        { path: 'language', component: LanguageComponent, canActivate: [ AuthGuardService ] },
        { path: 'portalinfo', component: PortalInfoComponent, canActivate: [ AuthGuardService ] },
        { path: 'icons', component: IconsComponent, canActivate: [ AuthGuardService ] },
        { path: 'uploadicon', component: UploadIconComponent, canActivate: [ AuthGuardService ] },
        { path: 'login', component: LoginComponent },
        { path: 'unauthorizeduser', component: UnauthorizedUserComponent },
        { path: 'change-password', component: ChangePasswordComponent },
        { path: 'change-pin', component: ChangePinComponent }
      ])
    ],
    providers: [
      CookieService,
      ConfirmationService,
      Home1Component,
      AppComponent,
      [{
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
      }],
      Title,
      {
        provide: KeycloakService,
        useValue: keycloakService
      },
      Data,
      UserappinstanceService,
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      },
      { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
    ],
    // bootstrap: [AppComponent],
    entryComponents: [
      AppComponent,
      NullComponent,
      LoginComponent
    ]
})

export class AppModule {

  constructor(
    private router: Router,
    public translate: TranslateService,
    private auth: AuthService
    ) {
      // router.navigateByUrl('/');
      localStorage.setItem('oi', '');
      localStorage.setItem('ui', '');
      // keycloakService.isLoggedIn().then(async (res) => {
      //   console.log(await keycloakService.isLoggedIn())
      //   console.log(res)
      //   if (res === true) {
      //     console.log(res)
      //     const url = new Urls();
      //     keycloakService.logout(url.appUrl + '/');
      //   }
      // });
    }

  urls = new Urls();
  variables = new Variables();
  ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    if (environment.useKeycloak === 'true' || environment.useKeycloak === 'false') {
      keycloakService
      .init(
        {
          config: {
            url: this.urls.keycloakUrl + '/auth',
            realm: this.variables.realm,
            clientId: this.variables.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
            promiseType: 'legacy'
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: [
              '/assets',
              '/clients/public']
        })
      .then(async () => {
        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
    } else {
      localStorage.setItem('browser', 'true');
      if (this.auth.isLoggedIn() === true) {
        app.bootstrap(AppComponent);
      } else {
        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(LoginComponent);
        localStorage.clear();
        this.router.navigateByUrl('/login');
      }
    }
  }

  handleError(err) {
    console.log(err);
  }

 }
