import { FileService } from './Services/file/file.service';
import { AuthService } from './Services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from './Services/users/users.service';
import { PortalInfoService } from './Services/portal-info/portal-info.service';
import { PortalInfo } from './Models/PortalInfo';
import { Urls } from './Models/Urls';
import { Router } from '@angular/router';
import { UserInstance } from './Models/UserInstance';
import { UserappinstanceService } from './Services/userappinstnce/userappinstance.service';
import { Component, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { KeycloakProfile } from 'keycloak-js';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { UserUpdater, KeyValue } from './Models/KeyValue';
import { Variables } from './Models/variables';
import * as CryptoJS from 'crypto-js';
import { FormBuilder } from '@angular/forms';
import { User } from './Models/User';
import { GroupsService } from './Services/groups/groups.service';
import { ImageService } from './Services/image/image.service';
import { LanguageService } from './Services/language/language.service';
import { PermissionService } from './Services/permission/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  load = true;

  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  http: any;

  urls = new Urls();

  isVisible = false;
  organisation: any;
  loading: boolean;

  pop = true;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  icon: any;

  constructor(
    // private keycloakService: KeycloakService,
    private router: Router,
    public translate: TranslateService,
    private portalinfo: PortalInfoService,
    private titleService: Title,
    private userService: UsersService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private usersService: UsersService,
    private auth: AuthService,
    private language: LanguageService,
    private fb: FormBuilder,
    private imageService: ImageService,
    private permService: PermissionService,
    private groupService: GroupsService,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private keycloakService: KeycloakService,
    private fileService: FileService
  ) {
      auth.clear();

      translate.addLangs(['English', 'French']);
      translate.setDefaultLang('English');

      let browserLang = translate.getBrowserLang();
      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
      // this.setLastLogin();
      router.navigateByUrl('/');
  }

  setLastLogin2() {
    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const upt = new UserUpdater();
    upt.Id = usr.userId;
    upt.Values = [];

    const llogin = new KeyValue();
    llogin.Key = 'lst_login';
    upt.Values.push(llogin);

    // tslint:disable-next-line: deprecation
    this.userService.putUser(upt.Id, upt).subscribe((res) => {
    }, err => {
      this.handleError(err);
    });
  }

  async ngOnInit() {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      this.portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));

          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);
    }
    // this.router.navigateByUrl('/home');
    await this.getImages();
    await this.setIcon();
    await this.getUser();
  }

  async getImages() {
    this.load = true;
    const res = await this.imageService.getImages().toPromise()
    .catch(err => this.handleError(err));
    if (res !== null) {
      for (const img of res) {
        if (img.name === 'icon') {
         if (img.img === undefined || img.img === null || img.img === '') {
           this.icon = null;
         } else {
          this.icon = this.domSanitizer.bypassSecurityTrustUrl(img.img);
         }
        } else if (img.name === 'image') {
        //  const image = this.domSanitizer.bypassSecurityTrustUrl(img.img);
       }
      }
    }
  }

  async setIcon() {
    this.loading = true;
    const file = await this.fileService.getFiles(1, 1, ' ', 'portal-icon', 'portal-bucket', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('portal-bucket', file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async getUser() {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    const ress = await this.userService.getUser(0, 0, eml, null).toPromise().catch(err => this.handleError(err));
    // Replace the user id with the users user id later
    const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
    if (lang.userId > 0) {
      const pass = 'ranitessarldev2019';
      // const enclanguage = lang.lang;
      const enclanguage = CryptoJS.AES.encrypt(lang.lang, pass).toString();
      const dom = environment.domain;
      this.cookieService.set('goepla-portal-language', enclanguage, 1000, '/', dom, false, 'Lax');
      localStorage.setItem('language', lang.lang);
    }

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.getGroups();
    } else {
      this.loading = false;
      this.pop = true;
      // this.router.navigateByUrl('/unauthorizeduser');
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
        this.confirm(resp);
      });
    }
  }

  async getGroups() {
    const v = new Variables();
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    const uid = +ui;
    let mygroups = '';
    let username = '';
    let groupids = '';
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs);
    // localStorage.setItem('my-groups', mygroups);

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    // localStorage.setItem('user-name', username);
    // localStorage.setItem('user-group-ids', groupids);

    await this.getPermissions(user, groupids);
  }

  async getPermissions(user, groupids) {
    const permissions = [];
    // const groupids = localStorage.getItem('user-group-ids');
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    const usr = new User();

    const v = new Variables();
    const us = CryptoJS.AES.encrypt(JSON.stringify(user), v.pass);
    localStorage.setItem('eu', us);

    await this.setLastLogin();

    this.pop = false;
    this.authService.login(usr);
    // window.location.reload();
    this.router.navigateByUrl('/home');
    this.loading = false;
  }

  async setLastLogin() {
    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const upt = new UserUpdater();
    upt.Id = usr.userId;
    upt.Values = [];

    const llogin = new KeyValue();
    llogin.Key = 'lst_login';
    upt.Values.push(llogin);

    await this.userService.putUser(upt.Id, upt).toPromise().catch(err => this.handleError(err));
  }

  gotoAccount() {
    window.open(this.urls.keycloakUrl + '/auth/realms/GoeplaRealm/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');
    this.cookieService.delete('goepla-portal-login');

    // this.keycloakService.logout(this.urls.appUrl + '/');
  }

  loadUserProfile() {
    /*this.keycloakService.loadUserProfile().then((user) => {
      this.fullName = user.firstName + ' ' + user.lastName;
    });*/
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
            this.auth.logout();
        },
        reject: (type) => {
          this.auth.logout();
        }
    });
  }

  handleError(err) {
    console.log(err);
    this.loading = false;

    localStorage.setItem('oi', '');
    localStorage.setItem('ui', '');
    return;
  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    localStorage.clear();
    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');
    this.cookieService.delete('goepla-portal-login');
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    localStorage.clear();
    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');
    this.cookieService.delete('goepla-portal-login');
  }

}
